<template>
  <div class="emission_product">
    <div class="emission_product__header">
      <page-title-action
        :title="$t('cfp_emissions_product.title_register_emissions_product')"
        :isFixedButton="isFixedButton"
        @handleActionByName="handleActionByName"
        :isHasData="isHasDataTable"
        :workflowData="workflowData"
      >
      </page-title-action>
    </div>
    <div v-if="handleShowEmissionsTable || handleShowBothTable" class="emission-product__body">
      <div class="emission-product__body__info">
        <title-bar :title="processDetailInfo?.process_name">
          <custom-dropdown
            :options="dropdownOptions"
            v-model="processDetailInfo.selectedOption"
            @getDataPulldown="getDataPulldown"
            :processDetailInfo="processDetailInfo"
            :isReadOnly="isReadOnlyDataTable"
          ></custom-dropdown>
        </title-bar>

        <div class="emission-product__body__info__unit-section">
          <div class="emission-product__body__info__unit-section__input-block">
            <label>{{ $t('cfp_emissions_product.total_discharge_emissions_product') }}</label>
            <div class="text-field">
              {{ formatNumber(totalEmissions) }} <span class="text-field-unit">kg〜CO2e</span>
            </div>
          </div>
          <div class="emission-product__body__info__unit-section__input-block emission-quantity">
            <label
              >{{ $t('cfp_emissions_product.number_of_applicable_products_emissions_product') }}
              <tooltip :tooltipText="getTooltipQuantityProduct" class="tooltip" />
            </label>
            <input-field
              :isNumberType="true"
              :isPositiveNumber="true"
              :isLargerThan0="true"
              class="input-custom color-textColor input-service-link"
              :contentTooltip="contentToolTipProductName"
              :isHasToolTip="isChangedProductNumber"
              :inputValue="processDetailInfo.productNumber"
              @update:inputValue="handleUpdateProductNumber"
              :isReadOnly="isReadOnlyDataTable"
            />
          </div>
          <div class="emission-product__body__info__unit-section__input-block">
            <label>{{ $t('cfp_emissions_product.emission_per_product_emissions_product') }}</label>
            <div class="text-field">
              {{ formatNumber(emissionsPerProduct) }}
              <span class="text-field-unit">kg〜CO2e/{{ processDetailInfo.productUnit }}</span>
            </div>
          </div>
        </div>
        <div class="emission-product__body__info__note-section">
          <label>{{ $t('cfp_emissions_product.note_emissions') }}</label>
          <input-field
            class="input-custom color-textColor input-service-link"
            :inputValue="processDetailInfo.note"
            @blur="handleCheckNote"
            :errorMessages="productEmissionsErrorMessages.noteErrorMessages"
            @focus="productEmissionsErrorMessages.noteErrorMessages = []"
            @update:inputValue="handleUpdateNoteField"
            :isReadOnly="isReadOnlyDataTable"
          />
        </div>
      </div>
      <div class="emission-product__body__table register-emission-product">
        <data-table
          :init-grid="initGrid"
          :data-source="emissionsProductGrid"
          :grid-columns="emissionsProductColumns"
          :allowRightClick="!isReadOnlyDataTable"
          :isShowToolbar="false"
          :rowFocus="1"
          :showFocus="false"
          :isReadOnly="isReadOnlyDataTable"
          :allowDelete="true"
          tableName="registerEmissionCfp"
          :basicUnitData="basicUnitData"
          :getListIdUnique="basicUnitData"
          :pasteDataEvent="pasteDataEvent"
          @updateAutoFill="handleUpdateAutoFill"
          @actionRightClick="actionRightClick"
          @checkTableHasData="handleContextPasted"
          @addValueSourceIntoGrid="handleAddValueSourceIntoGrid"
          @addValueToListName="addValueToList"
        />
      </div>
    </div>
    <div v-if="handleShowSupplierTable || handleShowBothTable" class="emission-product__body supplier-table">
      <data-table
        :init-grid="initSupplierGrid"
        :data-source="supplierCollection"
        :grid-columns="supplierColumns"
        :allowRightClick="false"
        :isShowToolbar="false"
        :rowFocus="1"
        :showFocus="false"
        :isReadOnly="false"
        :allowAddNew="false"
        tableName="registerEmissionCfp"
        :isBlockLoadingTable="true"
      />
    </div>
    <div v-if="isMobile" ref="scrollElement">
      <page-title-action
        :title="$t('cfp_emissions_product.title_register_product')"
        :workflowData="workflowData"
        :isFixedButton="isFixedButton"
        @handleActionByName="handleActionByName"
        :isHasData="isHasDataTable"
        hiddenIfDoNotHaveAnyButton
      ></page-title-action>
    </div>
    <evidence-storage-popup
      v-if="openDialogEvidenceStorage"
      :dialog.sync="openDialogEvidenceStorage"
      :typeForm="typeFormEvidenceStorage"
      :descriptionScope="descriptionScope"
      :evidences="evidences"
      @onSubmitSuccess="handleAddAttachFile"
      @onDelete="handleDeleteAttachFile"
      :isProcessProduct="true"
    />
    <notification-popup
      v-if="dialog.notification"
      :dialog="dialog.notification"
      :message="message.notification"
      :isErrorImg="true"
      @submit="dialog.notification = false"
    />
    <notification-popup
      v-if="dialog.notificationSaved"
      :dialog="dialog.notificationSaved"
      :message="message.savedChange"
      @submit="goToEmissionsProcessWorkflow"
    />
    <question-popup
      v-if="questionPopupDialog"
      :confirmText="confirmText"
      :dialog="questionPopupDialog"
      :message="questionPopupMessage"
      :closeText="closeText"
      :isSaveButtonDelete="isUpdateDataApproved"
      @close="closeQuestionPopup"
      @submit="submitQuestionPopup"
    />
    <basic-unit-popup
      v-if="basicUnitData.dialog"
      :dialog="basicUnitData.dialog"
      :getListIdUnique="basicUnitData"
      :objPatternData="objPatternData"
      @submitBasicUnitItem="submitBasicUnitItem"
      @close="closeBasicUnitPopup()"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import PageTitleAction from '@/components/products/common/page-title-action.vue';
import TitleBar from '@/components/products/table/TitleBar';
import CustomDropdown from '@/components/commonApp/CustomDropdown';
import InputField from '@/components/products/input/InputField.vue';
import DataTable from '@/components/v2/category/data-table';
import QuestionPopup from '@/components/dialogs/question-popup';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { CollectionView } from '@mescius/wijmo';
import { actionProcessEmissionProduct } from '@/api/product/emissionsProduct';
import { BLANK_ID } from '@/constants/registerData';
import * as wjcCore from '@mescius/wijmo';
import * as wjGrid from '@mescius/wijmo.grid';
import { AutoComplete } from '@mescius/wijmo.input';
import { getDbCustomizeOrDbMasterById, makeNewId } from '@/concerns/utils/master-or-customize-db';
import { DB_TYPE } from '@/constants/dbType';
import { ACTION_CODE } from '@/constants/rightClickAction.js';
import {
  math,
  formatBigNumber,
  validateNumberFieldBySetting,
  preventKeydownNumber,
  $_helper_isNumberType_bySetting,
  removeSpecialChars,
  formatNumberBySetting,
} from '@/concerns/newRegisterData/wijmo.helper';
import { formatNumberByConditions, formatNumberRealNum, addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { getDbRelationKeysByType } from '@/utils/sourceExternal';
import { getWidthOfAttachedButton, getWidthOfSearchButton } from '@/utils/multiLanguage';
import NotificationPopup from '@/components/dialogs/notification-popup';
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import { KEYS_CODE } from '@/constants/keyboard';
import i18n from '@/lang/i18n.js';
import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
import { ROUTES } from '@/router/constants';
import EvidenceStoragePopup from '@/views/emissions/EvidenceStorage/index.vue';
import { STATUS_FIELD } from '@/constants/status';
import { ROLE } from '@/constants/role';
import Tooltip from '@/components/commonApp/Tooltip';
import moment from 'moment';
import BasicUnitPopup from '@/components/dialogs/v2/basic-unit-popup';
import { getListUniqueId, getListUniqueItemName } from '@/api/basic-unit.js';
import { handleLostFocusBasicUnitPulldown, handleBasicUnitDroppedDownChanged } from '@/utils/registerData';
import {
  findManyByIdsDataBasicUnit,
  findManyByIdsDataBasicUnitItemNameNew,
  findOneByIdDataBasicUnit,
} from '@/utils/indexed-db/basicUnit';
import { addItemToPulldown } from '@/utils/indexed-db/flexgrid';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { ORGANIZATIONAL_DATA } from '@/constants/registerData.js';
import { getStatusNameById, getColorStatusById } from '@/utils/status';
import { TABLE_STATUS } from '@/constants/products/define-data';
export default {
  components: {
    PageTitleAction,
    TitleBar,
    InputField,
    DataTable,
    CustomDropdown,
    NotificationPopup,
    EvidenceStoragePopup,
    Tooltip,
    QuestionPopup,
    BasicUnitPopup,
  },
  data() {
    return {
      data: [],
      isLoading: false,
      inputValue: null,
      isHasDataSearched: false,
      isNextStepSearch: false,
      isSearching: false,
      serviceLinkData: {},
      flexGrid: null,
      emissionsProductGrid: null,
      emissionsProductColumns: [],
      isLoadingSubmit: false,
      emissionsProductData: [],
      listOriginalData: [],
      dropdownOptions: [
        { value: false, label: this.$t('cfp_emissions_product.label_no_cut_off_target') },
        { value: true, label: this.$t('cfp_emissions_product.label_cut_off_target') },
      ],
      totalEmissions: null,
      emissionsPerProduct: null,
      isFixedButton: true,
      isMobile: window.innerWidth < 1024,
      lostFocusId: null,
      codeProductValue: null,
      dbExternalAdded: {
        [DB_TYPE.IDEA]: [],
        [DB_TYPE.SERVICE_LINK]: [],
        [DB_TYPE.MASTER]: [],
        [DB_TYPE.CUSTOMIZE]: [],
      },
      openDialogEvidenceStorage: false,
      typeFormEvidenceStorage: 'view',
      onlyViewEvidence: false,
      evidences: {},
      itemsChangeEvidence: {},
      detailExternalSource: null,
      selectedRow: null,
      isHasDataTable: false,
      processDetailInfo: {
        id: null,
        note: '',
        selectedOption: false,
        productNumber: null,
        productUnit: '',
        noteOriginal: '',
        quantityOriginal: null,
        methodOriginal: false,
      },
      dialog: {
        notification: null,
        notificationSaved: null,
      },
      message: {
        notification: '',
        savedChange: '',
      },
      productEmissionsErrorMessages: {
        noteErrorMessages: [],
      },
      questionPopupDialog: false,
      questionPopupMessage: '',
      confirmText: '',
      closeText: '',
      isUpdateDataApproved: false,
      basicUnitData: {
        dialog: false,
        basicUnitListDefault: [],
        basicUnitPulldown: [],
        listPullDownByObjectName: {},
        listPullDownByObjectUniqueId: {},
        allBasicUnitByObjectName: {},
        allBasicUnitByObjectUniqueId: {},
        basicUnitUnique: [],
        allBasicUnitUnique: [],
        selectedValuePulldown: {},
        listUniqueIdForPulldown: [],
        listUniqueNameBasicForPulldown: [],
        listAllUniqueId: [],
        listAllUniqueItemName: [],
        listBasicUnitIdsByMonthYear: [],
      },
      isAddIdeaDb: false,
      lastPastingRow: 0,
      pasteDataEvent: {},
      isAllowPasteData: false,
      supplierGrid: null,
      supplierCollection: null,
      supplierColumns: [],
      listLayerBranch: [],
      supplierData: [],
      tableStatus: TABLE_STATUS.OLD_TABLE,
    };
  },
  async mounted() {
    // this.actionUpdateIsLoading(true)
    if (window.innerWidth < 1024) {
      this.isFixedButton = true;
    }
    this.emissionsProductColumns = this.getHeaderTable();
    window.addEventListener('resize', this.handleResize);
    await Promise.all([this.getListAllUniqueId(), this.getListAllUniqueItemName(), this.getEmissionProductByProcess()]);
    await Promise.all([this.getListUniqueIdForPullDown(), this.getListUniqueItemNameForPullDown()]);

    await Promise.all([this.defineTable(), this.defineSupplierTable()]);
    this.updateBreadCrumb(this.breadCrumb());
    document.addEventListener('scroll', this.updateActionPosition);
  },
  computed: {
    ...mapState('userData', ['existDbSource', 'currentUser']),
    ...mapGetters('settingApp', ['getDigitsBySetting']),
    statusApproved() {
      const status = this.processDetailInfo?.status_product_emissions;
      const isWorkflowApproved = [STATUS_FIELD.STATUS_REPRENSENT_APPROVED, STATUS_FIELD.STATUS_APPROVED].includes(
        status,
      );
      return isWorkflowApproved;
    },
    descriptionScope() {
      return {
        onlyView: this.onlyViewEvidence,
        subTitle: this.geSubTitleEvidence,
      };
    },
    isChangedProductNumber() {
      return this.processDetailInfo?.changed_columns?.quantity ? true : false;
    },
    contentToolTipProductName() {
      return this.getTootipContent(this.processDetailInfo?.changed_columns?.quantity);
    },
    getTooltipQuantityProduct() {
      return this.$t('cfp_emissions_product.tooltip_product_involved_register');
    },
    isReadOnlyDataTable() {
      const status = this.processDetailInfo?.status_product_emissions;
      const isOwner = this.processDetailInfo?.is_owner;
      const isUserNotAdmin = this.currentUser?.user?.role_id !== ROLE.ADMIN;
      const isStatusCanceled = status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED;
      const isStatusSubmitted = status === STATUS_FIELD.STATUS_SUBMIT;
      const isStatusDraft = status === STATUS_FIELD.STATUS_DRAFT;
      const isStatusReject = [STATUS_FIELD.STATUS_RETURN, STATUS_FIELD.STATUS_REPRENSENT_RETURN].includes(status);

      return (
        (isUserNotAdmin && this.statusApproved) ||
        isStatusCanceled ||
        isStatusSubmitted ||
        (isStatusDraft && !isOwner) ||
        (isStatusReject && !isOwner)
      );
    },
    geSubTitleEvidence() {
      const productName = this.$t('cfp_emissions_product.hyperlink_product_name', {
        productName: this.processDetailInfo?.product_name,
      });
      const boundaryName = this.processDetailInfo?.boundary_name || '';
      const processName = this.processDetailInfo?.process_name || '';
      return `${productName} > ${boundaryName} > ${processName}`.trim();
    },
    workflowData() {
      return {
        is_tmp: this.processDetailInfo.status_product_emissions === null ? 1 : 0,
        isReadOnlyDataTable: this.isReadOnlyDataTable,
        status_product_emissions: this.processDetailInfo.status_product_emissions,
      };
    },
    objPatternData() {
      return {
        month: this.processDetailInfo.month,
        year: this.processDetailInfo.year,
      };
    },
    handleShowEmissionsTable() {
      return this.tableStatus === TABLE_STATUS.OLD_TABLE;
    },
    handleShowSupplierTable() {
      return this.tableStatus === TABLE_STATUS.SUPPLIER_TABLE;
    },
    handleShowBothTable() {
      return this.tableStatus === TABLE_STATUS.BOTH;
    },
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoading', 'actionUpdateIsLoadingTable']),
    breadCrumb() {
      const breadCrumb = [
        {
          text: this.$t('b_list_submited.hyperlink_home'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
      ];
      const typeDetail = this.$route.query?.type;
      if (typeDetail === 'detail') {
        breadCrumb.push({
          text: this.$t('cfp_emissions_product.title_list_products'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        });
      } else if (typeDetail === 'submit') {
        breadCrumb.push({
          text: this.$t('b_list_submited.label_application_status'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_SUBMITTED_LIST}`,
        });
      } else if (typeDetail === 'approval') {
        breadCrumb.push({
          text: this.$t('left_menu.button_approval'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_APPROVED_LIST}`,
        });
      }
      breadCrumb.push(
        {
          text: this.$t('cfp_emissions_product.hyperlink_product_name', {
            productName: this.processDetailInfo?.product_name,
          }), //TODO: update text
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/product-detail/${this.processDetailInfo?.product_id}?type=${typeDetail}`,
        },
        {
          text: this.processDetailInfo?.boundary_name,
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/emissions-detail/${this.processDetailInfo?.product_emission_id}?type=${typeDetail}`,
        },
        {
          text: this.processDetailInfo?.process_name,
          disabled: true,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
      );
      return breadCrumb;
    },
    getHeaderTable(isUseAllDbBasicUnit = false) {
      if (!this.flexGrid) return [];

      const header = this.initGridColumn(isUseAllDbBasicUnit);
      if (!this.existDbSource) {
        this.removeHeaderItem(header, 'view_detail');
      }
      return header;
    },
    getTootipContent(item) {
      if (!item?.updated_at || !item?.updated_by) return '';
      let updatedAt = moment.utc(item.updatedAt).add(9, 'hours').format('YYYY/MM/DD HH:mm:ss');
      if (this.isCheckLang === 'vi') {
        return (
          `${this.$t('register_data.description_modified_by')} ${this.$t('register_data.description_administrator')} ${
            item.updated_by
          }\n` + updatedAt
        );
      } else {
        return item.updated_by + ` ${this.$t('register_data.description_modified_by')} \n` + updatedAt;
      }
    },
    goToEmissionsProcessWorkflow() {
      this.dialog.notificationSaved = false;
      this.$router
        .push({
          name: 'EmissionsDetailProduct',
          params: { emissionId: this.processDetailInfo?.product_emission_id },
          query: this.$route.query,
        })
        .catch(() => {});
    },
    initGrid(grid) {
      this.flexGrid = grid;
      let columnNum = null;
      if (this.flexGrid) {
        this.flexGrid.columnGroups = this.getHeaderTable();
      }

      document.addEventListener('keydown', (e) => {
        if (
          (e.metaKey || e.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(e.keyCode)
        ) {
          e.preventDefault();
        }
      });
      this.actionUpdateIsLoadingTable(false);
      grid.selectionChanged.addHandler((s, e) => {
        const column = s.columns[e.col];
        if (column.binding !== columnNum) {
          columnNum = column.binding;
        }
      });
      grid.hostElement.addEventListener(
        'keydown',
        (e) => {
          // console.log('keydown: ', e);
          preventKeydownNumber({
            event: e,
            getDigitsBySetting: this.getDigitsBySetting,
            columnNum,
            formatItems: ['amount_activity'],
          });
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(0, currentSelection.col);
              grid.selection = cellRange;
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
              grid.selection = cellRange;
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(currentSelection.row, 2);
              grid.selection = cellRange;
            }
          }

          if (e.keyCode === KEYS_CODE.ENTER) {
            if (grid.selection.row === grid.rows.length - 1 && !this.isReadOnlyDataTable) {
              const lastClientId = grid.itemsSource.itemCount;

              grid.deferUpdate(() => {
                grid.itemsSource.addNew(this.blankData(lastClientId + 1));
                grid.itemsSource.commitNew();
              });
            }
          }
          if (e.keyCode === KEYS_CODE.KEY_V) {
            if (this.isReadOnlyDataTable) return;
            this.handlePaste();
          }
        },
        false,
      );
      grid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel == s.cells && colBinding === 'attach_file') {
          if (e.cell.querySelector('button') && e.cell.querySelector('img')) {
            const img = e.cell.querySelector('img');
            let fileAttached = 'file_attached.svg';
            if (img) {
              if (this.statusApproved) {
                const rowData = s.rows[e.row].dataItem;
                if (rowData['changed_columns']?.evidence) {
                  fileAttached = 'file_attached_approved.svg';
                  if (e.cell.querySelector('div')) {
                    e.cell.querySelector('.file-attached-container').classList.add('approved');
                  }
                }
              } else {
                fileAttached = 'file_attached.svg';
              }
              img.setAttribute('src', require(`@/assets/icons/${fileAttached}`));
              e.cell.querySelector('button').addEventListener('mouseenter', () => {
                img.setAttribute('src', require('@/assets/icons/file_attached_active.svg'));
              });
              e.cell.querySelector('button').addEventListener('mouseleave', () => {
                img.setAttribute('src', require(`@/assets/icons/${fileAttached}`));
              });
            }
          }
        }
        if (e.panel == s.cells && colBinding === 'amount_activity') {
          const rowData = s.rows[e.row].dataItem;
          if (rowData) {
            const idServiceSource = rowData?.evidences;
            if (idServiceSource) {
              wjcCore.addClass(e.cell, 'auto-increment');
            }
          }
        }
        const buttonBinds = ['view_detail'];
        const buttonView = ['attach_file', 'basic_unit_search'];
        const isButtonDisabled = this.isReadOnlyDataTable && e.panel === s.cells && buttonBinds.includes(colBinding);
        const isButtonViewDisabled = this.isReadOnlyDataTable && e.panel === s.cells && buttonView.includes(colBinding);
        if (isButtonDisabled) {
          wjcCore.addClass(e.cell, 'btn-disabled');
        }
        if (isButtonViewDisabled) {
          const hasFileAttached = e.cell.querySelector('.file-attached-container') !== null;
          if (!hasFileAttached) {
            return wjcCore.addClass(e.cell, 'btn-disabled');
          } else {
            this.onlyViewEvidence = true;
            return;
          }
        }
      });
      grid.cellEditEnded.addHandler((s, e) => {
        let column = s.columns[e.col];
        const { row, col } = e.range;
        const cellData = s.getCellData(row, col, false);
        const view = s.collectionView;
        const source = view.sourceCollection;
        const currentItem = source[row] || {};
        const binding = column.binding;
        const dataStore = getDbCustomizeOrDbMasterById(this.basicUnitData.basicUnitPulldown);

        if (['amount_activity'].includes(column.binding)) {
          const isNumber = $_helper_isNumberType_bySetting(cellData);
          const range = 25;
          const subStringRang = cellData?.includes('-') ? range + 1 : range;
          const valueFormat = this.formatNumber25digit(removeSpecialChars(cellData)?.substring(0, subStringRang));
          s.setCellData(row, col, isNumber ? valueFormat : '', false, true);
        }

        const dataBinding = { s, binding, row, cellData, currentItem, dataStore };
        this.autoBindingDbSource(dataBinding);
        this.checkTableHasData();
      });
      grid.beginningEdit.addHandler((s, e) => {
        let column = s.columns[e.col];
        const { row, col } = e.range;
        const isDeleteTing = e.data && (e.data.key === 'Backspace' || e.data.key === 'Delete');
        const energy_type = s.rows[e.row].dataItem['energy_type'];
        if (isDeleteTing && ['wsu_unit', 'wsu_value'].includes(column.binding) && !energy_type) {
          s.setCellData(row, col, '', false, true);
        }
        if (column.binding === 'amount_activity') {
          const cellData = s.getCellData(row, col, false);
          const decimalSeparatorComma = [1, 3].includes(this.getDigitsBySetting);
          const formatCell = decimalSeparatorComma
            ? removeSpecialChars(cellData)?.replace(/\./g, ',')
            : removeSpecialChars(cellData);
          s.setCellData(row, col, formatCell, false, true);
        } else if (
          column.binding === 'wsu_unit' ||
          column.binding === 'wsu_value' ||
          column.binding === 'wsu_source' ||
          column.binding === 'emissions'
        ) {
          e.cancel = true;
        }
      });

      grid.deletedRow.addHandler(() => {
        this.checkTableHasData();
        this.updateTotalEmissionsHandler();
      });

      grid.pastingCell.addHandler((s, e) => {
        e.cancel = true;
      });

      grid.pasting.addHandler(async (s, e) => {
        this.lastPastingRow = e._rng.row2;
        const { col, col2 } = e.range;
        let listKeyBindingPasting = [];
        for (let index = col; index <= col2; index++) {
          const keyBindding = grid.columns[index].binding;
          if (keyBindding) listKeyBindingPasting.push(keyBindding);
        }
        this.actionUpdateIsLoadingTable(true);
        const permissionStatus = await navigator.permissions.query({ name: 'clipboard-read' });
        if (permissionStatus.state === 'prompt') {
          // show popup confirm allow/deny clipboard permission
          permissionStatus.onchange = () => {
            // watch when permission change
            if (permissionStatus.state === 'granted') {
              // click allow
              // permissionStatus.onchange = null;
              this.handleClipboardPermission({ listKeyBindingPasting, isGetDataClipboard: true });
            } else if (permissionStatus.state === 'denied') {
              console.log('User clicked Deny!');
            }
          };
        } else {
          await this.handleClipboardPermission({ listKeyBindingPasting });
        }
      });
      this.highlightChangedRows();
    },
    async handleClipboardPermission(dataProps) {
      const { listKeyBindingPasting, isGetDataClipboard } = dataProps;
      if (listKeyBindingPasting.includes('energy_type')) {
        // case has update header
        this.isAddIdeaDb = true;
        await this.handleUpdateBasicUnitColumn().then(() => {
          this.isAddIdeaDb = false;
          this.pasteDataEvent = {
            ...this.pasteDataEvent,
            isAllowPasteData: true,
            isUpdateDb: true,
            isGetDataClipboard: isGetDataClipboard,
          };
        })
      } else {
        this.pasteDataEvent = {
          ...this.pasteDataEvent,
          isAllowPasteData: true,
          isUpdateDb: false,
          isGetDataClipboard: isGetDataClipboard,
        };
      }

    },
    handlePastingSource(basicUnitName) {
      this.lostFocusId = null;
      const itemInPullDown = this.basicUnitData.listPullDownByObjectName[basicUnitName];
      if (itemInPullDown) return;
      const itemInListAll = this.basicUnitData.allBasicUnitByObjectName[basicUnitName];

      if (!itemInListAll) return;
      if (!this.isAddIdeaDb) {
        this.isAddIdeaDb = true;
        this.handleUpdateBasicUnitColumn(); // set header with all db item
      }

      const newId = makeNewId(itemInListAll?.id, itemInListAll?.type);
      this.handleNewDataForPullDown({ basicUnitName, newId, itemInListAll });
      this.lostFocusId = newId;
    },
    handleNewDataForPullDown(dataProps) {
      const { basicUnitName, newId, itemInListAll } = dataProps;
      const itemAddNew = { ...itemInListAll, item_name: itemInListAll.name_basic };
      this.basicUnitData.basicUnitPulldown.push(itemAddNew);
      this.basicUnitData.listPullDownByObjectUniqueId[newId] = itemAddNew;
      this.basicUnitData.listPullDownByObjectName[basicUnitName] = itemAddNew;
    },
    handleUpdateAutoFill(rowData, binding, dbIdea) {
      if (binding === 'energy_type') {
        // set basic unit data for row
        const databaseTypeObject = dbIdea;
        rowData.energy_type = dbIdea.id;
        rowData.wsu_source = databaseTypeObject?.source;
        rowData.wsu_value = this.formatNumber25digit(databaseTypeObject?.value_source);
        rowData.wsu_unit = databaseTypeObject?.unit_source;
      }
      const emissions = this.calculatorEmissions(rowData.wsu_value, rowData.amount_activity);
      const emission25Number = emissions?.toString()?.includes('-') ? 26 : 25;
      const newEmissions = this.formatNumber25digit(emissions?.toString()?.substring(0, emission25Number));
      rowData.emissions = newEmissions;
    },
    actionRightClick(action) {
      if (action === ACTION_CODE.DELETE) {
        this.checkTableHasData();
      }
    },
    highlightChangedRows() {
      if (this.emissionsProductData !== undefined && this.flexGrid) {
        const autoFields = ['wsu_unit', 'wsu_source', 'wsu_value'];
        this.flexGrid.formatItem.addHandler((handler, eventHandler) => {
          let accessRowIndex = eventHandler.row;
          let currentItem = this.emissionsProductData[accessRowIndex];
          if (
            eventHandler.panel.cellType === wjGrid.CellType.Cell ||
            eventHandler.panel.cellType === wjGrid.CellType.RowHeader
          ) {
            let currentItemChanged =
              currentItem !== undefined && currentItem['changed_columns'] !== undefined
                ? currentItem['changed_columns']
                : [];
            if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
              if (Object.keys(currentItemChanged).length) {
                eventHandler.cell.classList.add('wj-has-changed-mark');
              }
            } else {
              let column = handler.columns[eventHandler.col];

              if (autoFields.includes(column.binding) && currentItemChanged[column.binding]) {
                delete currentItemChanged[column.binding];
              }

              if ('name' in currentItemChanged) {
                currentItemChanged.item_name = currentItemChanged.name;
              }
              if ('activity_level' in currentItemChanged) {
                currentItemChanged.amount_activity = currentItemChanged.activity_level;
              }

              if ('activity_level_unit' in currentItemChanged) {
                currentItemChanged.amount_activity_unit = currentItemChanged.activity_level_unit;
              }

              if ('db_master_id' in currentItemChanged) {
                Object.values(autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged['db_master_id'];
                });
                currentItemChanged.energy_type = currentItemChanged['db_master_id'];
              }
              if ('db_customize_id' in currentItemChanged) {
                Object.values(autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged['db_customize_id'];
                });
                currentItemChanged.energy_type = currentItemChanged['db_customize_id'];
              }
              if ('db_relation' in currentItemChanged) {
                Object.values(autoFields).forEach((autoField) => {
                  currentItemChanged[autoField] = currentItemChanged['db_relation'];
                });
                currentItemChanged.energy_type = currentItemChanged['db_relation'];
              }
              if ('evidence' in currentItemChanged) {
                currentItemChanged.attach_file = currentItemChanged.evidence;
              }

              if (Object.keys(currentItemChanged).length && Object.keys(currentItemChanged).includes(column.binding)) {
                let tooltip = new wjcCore.Tooltip();
                eventHandler.cell.classList.add('wj-has-changed');
                let updatedAt = moment
                  .utc(currentItemChanged[column.binding].updated_at)
                  .add(9, 'hours')
                  .format('YYYY/MM/DD HH:mm:ss');
                let updatedBy = currentItemChanged[column.binding].updated_by;
                if (this.isCheckLang === 'vi') {
                  tooltip.setTooltip(
                    eventHandler.cell,
                    `<div class='wj-cell-changed'>${this.$t('register_data.description_modified_by')} ` +
                      ` ${this.$t('register_data.description_administrator')}<br/>` +
                      updatedBy +
                      ' ' +
                      updatedAt +
                      '</div>',
                  );
                } else {
                  tooltip.setTooltip(
                    eventHandler.cell,
                    `<div class='wj-cell-changed'>${this.$t('register_data.description_administrator')} ` +
                      updatedBy +
                      ` ${this.$t('register_data.description_modified_by')}<br/>` +
                      updatedAt +
                      '</div>',
                  );
                }
              }
            }
          }
        });
      }
    },
    handleContextPasted() {
      this.checkTableHasData();
      this.updateTotalEmissionsHandler(true);
      this.actionUpdateIsLoadingTable(false);
    },
    updateTotalEmissionsHandler(isSetEmissions = false) {
      let sourceCollectionCurrent = this.emissionsProductGrid?.sourceCollection;
      this.totalEmissions = 0;
      sourceCollectionCurrent?.forEach((item) => {
        if (item?.id === 'Blank' && !item['emissions']) return true;
        const newEmissionCalc = this.calculatorEmissions(item.wsu_value, item.amount_activity);
        if (!newEmissionCalc) return;
        this.totalEmissions = formatBigNumber(
          math.evaluate(
            `${math.bignumber(this.totalEmissions).toString()} + ${math
              .bignumber(newEmissionCalc?.substring(0, 50))
              .toString()}`,
          ),
          50,
        );
        if (isSetEmissions) {
          const emission25Number = newEmissionCalc?.toString()?.includes('-') ? 26 : 25;
          const newnewEmissionCalc = this.formatNumber25digit(
            newEmissionCalc?.toString()?.substring(0, emission25Number),
          );
          item['emissions'] = newnewEmissionCalc;
        }
      });
      this.handleCalcEmissionsPerProduct();
    },

    formatNumber(num) {
      if (num === 0) {
        return '---';
      }
      return formatNumberByConditions(
        addThoundSandCommaWithTwoDigit(formatBigNumber(num, 35).replace(/[^0-9\.\-]+/g, ''), '', false, true),
        {
          isRealNumber: true,
          isGetMoreZeroInDecimalPart: true,
        },
      );
    },

    async autoBindingDbSource(dataBinding) {
      const { s, binding, row, cellData, currentItem, dataStore } = dataBinding;
      if (binding === 'amount_activity') {
        s.deferUpdate(() => {
          const emissions = this.calculatorEmissions(currentItem.wsu_value, currentItem.amount_activity);
          if (emissions || emissions === 0) {
            const emission25Number = emissions?.toString()?.includes('-') ? 26 : 25;
            const newEmissions =
              emissions === null
                ? null
                : this.formatNumber25digit(emissions?.toString()?.substring(0, emission25Number));
            s.setCellData(row, 'emissions', newEmissions, false, true);
          }
          this.updateTotalEmissionsHandler();
        });
      }

      if (binding === 'energy_type') {
        let dbItem = await findOneByIdDataBasicUnit(cellData);
        if (this.lostFocusId && !dbItem) {
          dbItem = dataStore[this.lostFocusId];
        }

        s.deferUpdate(() => {
          const { SERVICE_LINK, IDEA } = DB_TYPE;
          currentItem.idDbExternal = ![SERVICE_LINK, IDEA].includes(dbItem?.type) ? null : dbItem?.id;
          if (dbItem) {
            if (dbItem?.type === SERVICE_LINK) {
              s.rows[row].dataItem.detailExternalProductCode = currentItem.idDbExternal;
              s.setCellData(row, 'amount_activity', dbItem.numberValue, false, true);
            } else {
              s.rows[row].dataItem.detailExternalProductCode = null;
            }

            s.setCellData(row, 'energy_type', dbItem.id, false, true);
            s.setCellData(row, 'wsu_value', this.formatNumber25digit(dbItem.value_source), false, true);
            s.setCellData(row, 'wsu_unit', dbItem.unit_source, false, true);

            const emissions = this.calculatorEmissions(currentItem.wsu_value, currentItem.amount_activity);
            const emission25Number = emissions?.toString()?.includes('-') ? 26 : 25;
            const newEmissions = this.formatNumber25digit(emissions?.toString()?.substring(0, emission25Number));
            s.setCellData(row, 'emissions', newEmissions, false, true);
          } else {
            if (
              !(currentItem.cfp_workflow_supplier_id && currentItem.type === null && currentItem.energy_type !== null)
            ) {
              s.setCellData(row, 'wsu_value', null, false, true);
              s.setCellData(row, 'wsu_unit', null, false, true);
              s.setCellData(row, 'energy_type', null, false, true);
            }

            // supplier data dont has dbItem but has emissions
            const emissions = this.calculatorEmissions(currentItem.wsu_value, currentItem.amount_activity);
            if (emissions || emissions === 0) {
              const emission25Number = emissions?.toString()?.includes('-') ? 26 : 25;
              const newEmissions =
                emissions === null
                  ? null
                  : this.formatNumber25digit(emissions?.toString()?.substring(0, emission25Number));
              s.setCellData(row, 'emissions', newEmissions, false, true);
            }
          }
          this.updateTotalEmissionsHandler();
        });
      }
    },

    calculatorEmissions(valueSource, value) {
      if ($_helper_isNumberType_bySetting(valueSource) && $_helper_isNumberType_bySetting(value)) {
        const valSource = math.bignumber(formatNumberBySetting(valueSource));
        const valSale = math.bignumber(formatNumberBySetting(value));
        return formatBigNumber(math.evaluate(`${valSource.toString()} * ${valSale.toString()}`), 50);
      }
      if (
        (valueSource === null || valueSource === '' || valueSource === undefined) &&
        (value === null || value === '' || value === undefined)
      ) {
        return null;
      }
      return 0;
    },
    handleCalcEmissionsPerProduct() {
      this.emissionsPerProduct =
        this.processDetailInfo.productNumber && this.totalEmissions !== 0
          ? formatBigNumber(
              math.evaluate(
                `${math.bignumber(this.totalEmissions).toString()} / ${math
                  .bignumber(formatNumberBySetting(this.processDetailInfo.productNumber))
                  .toString()} `,
              ),
              35,
            ).replace(/[^0-9\.\-]+/g, '')
          : 0;
    },

    initGridColumn() {
      return [
        {
          header: '連番',
          binding: 'id',
          minWidth: 40,
          maxWidth: 65,
          allowSorting: false,
          isReadOnly: true,
          visible: false,
          isRequired: false,
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_item_name'),
          binding: 'item_name',
          minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_item_name')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          wordWrap: true,
          align: 'left',
          multiLine: true,
        },
        {
          header: i18n.t('basic_unit_library.label_search_basic_unit'),
          binding: 'basic_unit_search',
          allowSorting: false,
          minWidth: getWidthByTextContent(this.$t('basic_unit_library.label_search_basic_unit')),
          maxWidth: getWidthOfSearchButton(i18n.locale),
          wordWrap: true,
          cssClassAll: 'search-detail hide-filter',
          isRequired: false,
          cellTemplate: CellMaker.makeButton({
            text: `<div class="search-container">
                    ${i18n.t('register_data.button_search')}
                    <img src="${require('@/assets/icons/pulldown/search-external.svg')}" alt="search icon" class="search-icon">
                  </div>`,
            click: (e, ctx) => this.basicUnitSearchPopup(e, ctx),
          }),
        },
        {
          header: this.$t('basic_unit_library.basic_unit_name'),
          binding: 'energy_type',
          minWidth: getWidthByTextContent(this.$t('basic_unit_library.basic_unit_name')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          wordWrap: true,
          align: 'left',
          dataMap: new wjGrid.DataMap([], 'id', 'name_basic'),
          editor: new AutoComplete(document.createElement('div'), {
            itemsSource: [],
            selectedValuePath: 'id',
            displayMemberPath: 'name_basic',
            maxItems: 1000,
            minLength: 1,
            selectedIndex: -1,
            isDroppedDownChanged: (sender) => {
              handleBasicUnitDroppedDownChanged({
                sender,
                dataProps: {
                  selectedValuePulldown: this.basicUnitData.selectedValuePulldown,
                  grid: this.flexGrid,
                },
              });
            },
            lostFocus: (sender, ctx) => {
              handleLostFocusBasicUnitPulldown({
                sender,
                ctx,
                dataProps: {
                  selectedValuePulldown: this.basicUnitData.selectedValuePulldown,
                  grid: this.flexGrid,
                },
              });
              this.handlePastingSource(sender.text, false);
            },
          }),
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_amount_activity'),
          binding: 'amount_activity',
          minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_amount_activity'), 5),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          wordWrap: true,
          align: 'right',
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_amount_activity_unit'),
          binding: 'amount_activity_unit',
          minWidth: getWidthByTextContent(
            this.$t('cfp_emissions_product.table_emissions_product_amount_activity_unit'),
          ),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          wordWrap: true,
          align: 'left',
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_emissions_intensity'),
          align: 'center',
          columns: [
            {
              header: this.$t('cfp_emissions_product.table_emissions_product_number'),
              binding: 'wsu_value',
              minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_number')) + 5,
              maxWidth: 890,
              allowSorting: false,
              isRequired: false,
              isReadOnly: false,
              cssClass: 'auto-increment',
              wordWrap: true,
              align: 'right',
            },
            {
              header: this.$t('cfp_emissions_product.table_emissions_product_unit'),
              binding: 'wsu_unit',
              minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_unit')) + 5,
              maxWidth: 890,
              allowSorting: false,
              isRequired: false,
              isReadOnly: false,
              cssClass: 'auto-increment',
              wordWrap: true,
            },
          ],
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_emission'),
          binding: 'emissions',
          minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_emission')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          wordWrap: true,
          align: 'right',
        },
        {
          header: this.$t('56_pattern.table_attach_file'),
          binding: 'attach_file',
          cssClass: 'auto-increment',
          minWidth: getWidthOfAttachedButton(this.$i18n.locale),
          maxWidth: getWidthOfAttachedButton(this.$i18n.locale),
          allowSorting: false,
          isRequired: false,
          cssClassAll: 'btn-db attach-file hide-filter',
          cellTemplate: (ctx, el) => {
            let text = this.$t('56_pattern.table_attach_file');
            if (ctx.item?.evidence) {
              text = `<div class="file-attached-container">
                  <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
                </div>`;
            }
            const button = CellMaker.makeButton({
              text: text,
              click: (e, context) => this.attachFilePopup(e, context),
            });
            return button(ctx, el);
          },
        },
      ];
    },
    handleCheckNote(value) {
      if (value.length > 255) {
        this.productEmissionsErrorMessages.noteErrorMessages.push(
          this.$t('validation.error_maximum_by_charactors', { charactors: '255' }),
        );
      }
    },
    attachFilePopup(e, context) {
      this.evidences = {};
      this.selectedRow = context.row.index;

      this.descriptionScope.detailExternalProductCode = context.item?.detailExternalProductCode;
      let evidenceData = context?.item?.evidence;
      if (typeof evidenceData === 'string') {
        evidenceData = JSON.parse(evidenceData);
      }
      this.onlyViewEvidence = false;

      if (evidenceData && evidenceData.file_name) {
        this.evidences.data = evidenceData;
        this.typeFormEvidenceStorage = 'view';
      } else {
        this.typeFormEvidenceStorage = 'create';
      }
      this.evidences.value = this.emissionsProductGrid.items[this.selectedRow]
        ? formatNumberBySetting(this.emissionsProductGrid.items[this.selectedRow]['amount_activity'])
        : null;
      this.openDialogEvidenceStorage = true;
    },
    updateEmissionsForRow(rowIndex) {
      const row = this.emissionsProductGrid.sourceCollection[rowIndex];
      const emissions = this.calculatorEmissions(row.wsu_value, row.amount_activity);
      const emission25Number = emissions?.toString()?.includes('-') ? 26 : 25;
      const newEmissions = this.formatNumber25digit(emissions?.toString()?.substring(0, emission25Number));

      row.emissions = newEmissions;
    },
    handleAddAttachFile(dataEvidence) {
      let evidenceDataInCellTable = this.emissionsProductGrid.items[this.selectedRow]?.evidence;
      this.emissionsProductGrid.editItem(this.emissionsProductGrid.items[this.selectedRow]);
      dataEvidence = {
        ...evidenceDataInCellTable,
        ...dataEvidence,
        created_at: evidenceDataInCellTable ? evidenceDataInCellTable.created_at : dataEvidence.created_at, // TODO: always get the first created date of the record
      };
      const numericValue = dataEvidence?.value;
      const rowIndex = this.selectedRow;
      this.updateFlexGridCells(rowIndex, {
        amount_activity: numericValue,
        attach_file: null,
      });
      this.updateFlexGridCells(rowIndex, {
        amount_activity: numericValue,
        attach_file: dataEvidence?.file_name,
      });
      if (this.emissionsProductGrid.items[rowIndex] && dataEvidence?.file_name) {
        this.emissionsProductGrid.items[rowIndex].evidence = dataEvidence;
        this.emissionsProductGrid.items[rowIndex].attach_file = dataEvidence.file_name;
      }
      if (!isNaN(parseInt(numericValue))) {
        this.emissionsProductGrid.items[rowIndex]['amount_activity'] = numericValue;
      } else {
        this.emissionsProductGrid.items[rowIndex]['amount_activity'] = '';
      }
      this.updateEmissionsForRow(rowIndex);
      this.updateTotalEmissionsHandler();
      this.checkTableHasData();

      const isHasChangeEvidence = this.checkEvidenceChangeData(dataEvidence, evidenceDataInCellTable);
      if (isHasChangeEvidence) {
        const itemChange = this.emissionsProductGrid.items[rowIndex];
        if (itemChange.id) {
          this.itemsChangeEvidence[itemChange.id] = { ...itemChange, rowIndex: rowIndex };
        }
        setTimeout(() => {
          // this.isEditDataTable = true;
          this.isChangeEvidence = true;
        }, 100);
      }
      this.emissionsProductGrid.commitEdit();
    },
    checkEvidenceChangeData(newEvidence, oldEvidence) {
      return !Object.keys(newEvidence).every(
        (key) => newEvidence?.key && oldEvidence?.key && newEvidence[key] === oldEvidence[key],
      );
    },
    handleDeleteAttachFile() {
      this.evidences = {};
      this.updateFlexGridCells(this.selectedRow, {
        attach_file: null,
      });
      this.emissionsProductGrid.sourceCollection[this.selectedRow].evidence = null;
      this.emissionsProductGrid.sourceCollection[this.selectedRow].attach_file = null;
      this.checkTableHasData();
    },
    updateFlexGridCells(rowIndex, cellData) {
      if (!this.emissionsProductGrid.sourceCollection[rowIndex]) {
        this.addBlankItemsToView(1);
      }
      for (const columnName in cellData) {
        const colIndex = this.flexGrid.getColumn(columnName)?.index;
        if (colIndex >= 0) {
          this.flexGrid.setCellData(rowIndex, colIndex, cellData[columnName], false, true);
        }
      }
    },
    removeHeaderItem(header, bindingName) {
      const indexRemoved = header.findIndex((h) => h.binding === bindingName);
      if (indexRemoved !== -1) {
        header.splice(indexRemoved, 1);
      }

      if (indexRemoved === -1) {
        header.forEach((headerItem) => {
          if (headerItem.columns?.length > 0) {
            const subIndexRemoved = headerItem.columns.findIndex((sub) => sub.binding === bindingName);
            if (subIndexRemoved !== -1) {
              headerItem.columns.splice(subIndexRemoved, 1);
            }
          }
        });
      }
    },
    initialView() {
      if (this.emissionsProductGrid) {
        this.emissionsProductData = this.emissionsProductGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
      }

      this.emissionsProductGrid = new CollectionView([...this.emissionsProductData], {
        trackChanges: true,
      });
      this.updateTotalEmissionsHandler();
      this.addBlankItemsToView(10);
      this.emissionsProductGrid.clearChanges();
      setMinMaxSizeColumns(this.flexGrid, this.emissionsProductData);

      this.listOriginalData = JSON.parse(JSON.stringify(this.emissionsProductGrid.items));
      this.emissionsProductGrid.currentItem = null;
    },
    addBlankItemsToView(count) {
      const lastClientId = this.emissionsProductGrid.itemCount;
      for (let index = 1; index <= count; index++) {
        this.emissionsProductGrid.addNew(this.blankData(lastClientId + index));
      }

      this.emissionsProductGrid.commitNew();
      // this.emissionsProductGrid.clearChanges();
    },
    blankData(clientRowId) {
      return {
        clientRowId: clientRowId,
        id: BLANK_ID,
        item_name: null,
        energy_type: null,
        amount_activity: null,
        amount_activity_unit: null,
        wsu_value: null,
        wsu_unit: null,
        wsu_source: null,
        emissions: null,
      };
    },
    handleUpdateProductNumber(newVal) {
      this.processDetailInfo.productNumber = newVal;
      this.handleCalcEmissionsPerProduct();
      this.checkTableHasData();
    },
    handleUpdateNoteField(newValue) {
      this.processDetailInfo.note = newValue;
      this.checkTableHasData();
    },
    async defineTable() {
      // set basic unit pulldown data;
      const dbList = await findManyByIdsDataBasicUnit(this.basicUnitData.listUniqueIdForPulldown, true);
      const column = this.flexGrid.getColumn('energy_type');
      column.dataMap = new wjGrid.DataMap(dbList, 'id', 'name_basic');
      column.editor.itemsSource = dbList;
      this.initialView();
      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    async getEmissionProductByProcess() {
      await actionProcessEmissionProduct('get', this.$route?.params?.processId)
        .then(async (res) => {
          const getEnergyType = (sourceArray) => Object.keys(getDbCustomizeOrDbMasterById(sourceArray))[0];
          const createSourceTemplate = (dbSource, dbType) => {
            const baseTemplate = {
              ...dbSource,
              source: dbSource.source,
              unit_source: dbSource.unit_source,
              value_source: dbSource.value_source,
              type: dbType,
              item_name: dbSource.source,
              unit: dbSource.unit,
              name_basic: dbSource?.source,
            };
            if (dbType === DB_TYPE.SERVICE_LINK) {
              baseTemplate.id = dbSource.product_package_code;
            }
            return baseTemplate;
          };
          const listDbItemsDetail = [];
          this.emissionsProductData = res.data.process_emissions.map((item, index) => {
            const subStringRang = item?.activity_level?.toString()?.includes('-') ? 26 : 25;
            const { db_customize, db_source_detail, db_master, type, service_product_package_link } = item;

            let dbSource = '';
            let energyType = '';
            let sourceTemplate;

            switch (type) {
              case DB_TYPE.CUSTOMIZE:
                dbSource = db_customize;
                energyType = db_customize.status === true ? getEnergyType([db_customize]) : db_customize.item_name;
                listDbItemsDetail.push(energyType);
                this.handleDefaultSelectedValuePulldown({
                  index,
                  item: {
                    id: energyType,
                    name_basic: dbSource.item_name,
                  },
                });
                break;
              case DB_TYPE.IDEA:
                dbSource = db_source_detail[0];
                energyType = getEnergyType(
                  db_source_detail.map((detail) => ({ ...detail, type: item.type, name_basic: item.source })),
                );
                listDbItemsDetail.push(makeNewId(dbSource.id, DB_TYPE.IDEA));
                this.handleDefaultSelectedValuePulldown({
                  index,
                  item: {
                    id: makeNewId(dbSource.id, DB_TYPE.IDEA),
                    name_basic: dbSource.source,
                  },
                });
                break;
              case DB_TYPE.SERVICE_LINK:
                dbSource = service_product_package_link[0];
                energyType = getEnergyType(
                  service_product_package_link.map((detail) => ({
                    ...detail,
                    type: item.type,
                    id: detail.product_package_code,
                  })),
                );
                sourceTemplate = createSourceTemplate(dbSource, DB_TYPE.SERVICE_LINK);
                this.addedDbExternalToMasterDB(sourceTemplate);
                break;
              case DB_TYPE.MASTER:
                dbSource = db_master;
                db_master.type = 1;
                energyType = getEnergyType([db_master]);
                listDbItemsDetail.push(energyType);
                this.handleDefaultSelectedValuePulldown({
                  index,
                  item: {
                    id: energyType,
                    name_basic: dbSource.name_basic,
                  },
                });
                break;
            }
            const wsuValue = dbSource.value_source || null;
            const wsuUnit = dbSource.unit_source || null;
            const wsuSource = dbSource.source || null;
            const evidence = typeof item?.evidence === 'string' ? JSON.parse(item?.evidence) : item?.evidence;
            return {
              ...item,
              item_name: item.name,
              amount_activity: item.activity_level
                ? this.formatNumber25digit(item.activity_level?.substring(0, subStringRang))
                : null,
              amount_activity_unit: item.activity_level_unit,
              energy_type: energyType,
              wsu_value:
                type === null
                  ? this.formatNumber25digit(item.value_source?.substring(0, subStringRang))
                  : this.formatNumber25digit(wsuValue?.toString()),
              wsu_unit: type === null ? item.unit_source : wsuUnit,
              wsu_source: type === null ? item.source : wsuSource,
              attach_file: evidence?.file_name,
              evidence: evidence,
              emissions: this.formatNumber25digit(item.emissions),
              clientRowId: index + 1,
            };
          });

          this.processDetailInfo = res.data.process_detail;
          this.processDetailInfo.productNumber =
            this.formatNumber25digit(this.processDetailInfo.quantity?.toString()) || null;
          this.processDetailInfo.note = this.processDetailInfo?.note || '';
          this.processDetailInfo.selectedOption = this.processDetailInfo?.calculation_object || false;
          this.processDetailInfo.productUnit = this.processDetailInfo?.product_unit;
          this.processDetailInfo.noteOriginal = this.processDetailInfo?.note || '';
          this.processDetailInfo.methodOriginal = this.processDetailInfo.calculation_object;
          this.processDetailInfo.quantityOriginal = this.processDetailInfo?.quantity || null;

          // handle for supplier table
          this.supplierData =
            res.data?.process_emission_supplier?.data.map((item) => {
              const workflowData = item.workflowData;
              const approveInfo = this.isReturnOrReprensentReturnStatus(workflowData.status)
                ? item.workflowData?.returner[0]?.name
                : item.workflowData?.approver?.name;

              const dateApprove = this.isReturnOrReprensentReturnStatus(workflowData.status)
                ? item.workflowData?.return_at[0]?.return_at
                : item.workflowData?.approved_at[0]?.approved_at;
              return {
                ...item,
                id: item.id,
                status: this.statusDataDetails(workflowData.status),
                linked_form_name: workflowData?.title,
                approver: approveInfo || null,
                sent_date: formatDateTimeDataTable(workflowData?.submitted_at[0]?.submitted_at) || null,
                date_approved: formatDateTimeDataTable(dateApprove) || null,
                organizational_division: ORGANIZATIONAL_DATA[item.organizational_division],
                company_name: item?.company_name || null,
                note: workflowData?.note,
                note_2: workflowData?.note_2,
                product_name: item?.name_product || null,
                product_code: item?.code || null,
                item_name: item?.name || null,
                activity_level: formatNumberRealNum(item?.activity_level, { isAlowMore25digit: false }) || null,
                activity_level_unit: item?.activity_level_unit || null,
                attach_file: item?.evidence || null,
              };
            }) || [];
          this.listLayerBranch = res.data?.process_emission_supplier?.layer;
          this.tableStatus = res?.data?.type || TABLE_STATUS.OLD_TABLE;
          await this.handleGetIdeaDetail(listDbItemsDetail);
        })
        .catch(() => {
          const type = this.$route.query?.type;
          if (type === 'submit') {
            this.$router.push({ name: 'ProductsListSubmittedNew' }).catch(() => {});
          } else if (type === 'approval') {
            this.$router.push({ name: 'ProductsApprovalNew' }).catch(() => {});
          } else {
            this.$router.push({ name: 'ProductsEmissionNew' }).catch(() => {});
          }
        });
    },
    submitQuestionPopup() {
      this.questionPopupDialog = false;
      if (this.isUpdateDataApproved) {
        this.submitData();
      } else {
        this.$router
          .push({
            name: 'EmissionsDetailProduct',
            params: { emissionId: this.processDetailInfo?.product_emission_id },
            query: this.$route.query,
          })
          .catch(() => {});
      }
    },
    closeQuestionPopup() {
      this.isUpdateDataApproved = false;
      this.questionPopupDialog = false;
    },
    handleActionByName(nameAction) {
      if (nameAction === 'clickSaveEmissionsBtn') {
        this.clickSaveEmissionsBtn();
      } else {
        this.isUpdateDataApproved = false;
        this.questionPopupDialog = true;
        this.questionPopupMessage = this.$t('cfp_emissions_product.message_confirm_cancel_register_emissions');
        this.confirmText = this.$t('cfp_emissions_product.button_confirm_cancel_register_emissions_yes');
        this.closeText = this.$t('cfp_emissions_product.button_confirm_cancel_register_emissions_no');
      }
    },
    checkValidateAndShowPopupIfError() {
      let messageList = [];
      if (!this.processDetailInfo.productNumber) {
        messageList.push(this.$t('cfp_emissions_product.message_required_number_of_product'));
      }
      if (messageList.length > 0) {
        this.dialog.notification = true;
        this.message.notification = messageList.join('\n');
        return true;
      }
    },
    getError(item, propName) {
      const itemsInView = [
        'item_name',
        'amount_activity',
        'amount_activity_unit',
        'energy_type',
        'emissions',
        'wsu_unit',
        'wsu_value',
        'wsu_source',
        'attach_file',
      ];
      if (itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
        return null;
      }
      switch (propName) {
        case 'wsu_unit':
        case 'wsu_value':
        case 'emissions':
        case 'amount_activity_unit':
          return this.emptyCell(item[propName]);
        case 'amount_activity':
          if (item[propName] === undefined || item[propName] === null || item[propName] === '') {
            return this.$t('validation.error_required_field');
          } else {
            return validateNumberFieldBySetting(String(item[propName]), propName);
          }
        case 'energy_type':
        case 'wsu_source':
          // If wsu unit + wsu valu has data && is cfp supplier => remove logic validate energy type
          if (!(item.cfp_workflow_supplier_id && item.wsu_unit && item.wsu_value)) {
            return this.emptyCell(item[propName]);
          }
        case 'item_name':
          return validateMaximumCharactorOnlyFacility(String(item[propName]), 255);
      }
      return null;
    },

    emptyCell(item) {
      if (item === undefined || item === null || item === '') {
        return this.$t('validation.error_required_field');
      }
      return null;
    },
    addedDbExternalToMasterDB(sourceTemplate, isCustomDbId) {
      const typeSource = sourceTemplate.type;
      if (!this.dbExternalAdded[typeSource]?.find((db) => db.id === sourceTemplate.id)) {
        this.dbExternalAdded[typeSource]?.push(sourceTemplate);
        if (typeSource === DB_TYPE.SERVICE_LINK) {
          this.basicUnitData.basicUnitPulldown.push(sourceTemplate);
        } else if (typeSource === DB_TYPE.IDEA) {
          // [master database, IDEA source ,[new item], service linked]
          this.basicUnitData.basicUnitPulldown.splice(
            this.basicUnitData.basicUnitPulldown.length - this.dbExternalAdded[DB_TYPE.SERVICE_LINK].length,
            0,
            sourceTemplate,
          );
          const newDb = isCustomDbId
            ? { ...sourceTemplate, id: makeNewId(sourceTemplate.id, sourceTemplate.type), origin_id: sourceTemplate.id }
            : sourceTemplate;
          this.basicUnitData.basicUnitUnique.push(newDb);
          if (isCustomDbId)
            this.basicUnitData.listPullDownByObjectUniqueId[makeNewId(sourceTemplate.id, sourceTemplate.type)] = {
              ...newDb,
            };
        }
      }
    },
    clickSaveEmissionsBtn() {
      const isErr = this.checkValidateAndShowPopupIfError();
      this.emissionsProductGrid.getError = this.getError;
      if (isErr || this.hasTableErrors(this.flexGrid)) {
        return;
      }

      this.submitData();
    },
    hasTableErrors(grid) {
      for (let r = 0; r < grid.rows.length; r++) {
        let row = grid.rows[r].dataItem;
        for (let c = 0; c < grid.columns.length; c++) {
          let col = grid.columns[c];
          if (row) {
            let error = this.getError(row, col.binding);
            if (error) {
              return true;
            }
          }
        }
      }

      return false;
    },

    formatValueNumber(input) {
      return input ? formatNumberBySetting(input) : '';
    },
    isValidFields(data) {
      return data?.every((item) => {
        // if record is supplier data = has type
        const hasType =
          (item.type !== '' && item.type !== null && item.type !== undefined) || item.cfp_workflow_supplier_id;
        const hasActivityLevel =
          item.activity_level !== '' && item.activity_level !== null && item.activity_level !== undefined;
        const hasActivityLevelUnit =
          item.activity_level_unit !== '' &&
          item.activity_level_unit !== null &&
          item.activity_level_unit !== undefined;
        // case delete on cell: !hasType && !hasActivityLevel && !hasActivityLevelUnit && name === '' and does not exist evidence
        return (
          (hasType && hasActivityLevel && hasActivityLevelUnit) ||
          (!hasType && !hasActivityLevel && !hasActivityLevelUnit && item.name === '' && !item.evidence)
        );
      });
    },
    // TODO: Change payload or not
    async submitData() {
      let payload = {
        process_detail: {
          boundary_id: this.processDetailInfo.boundary_id,
          product_id: this.processDetailInfo.product_id,
          product_emission_id: this.processDetailInfo.product_emission_id,
          process_id: this.processDetailInfo.process_id,
          calculation_object: this.processDetailInfo.selectedOption,
          note: this.processDetailInfo.note,
          quantity: this.formatValueNumber(this.processDetailInfo.productNumber),
        },
      };
      const dataEditedOnTable = await this.getDataChangeOnTable();
      if (!this.isValidFields(dataEditedOnTable)) return;
      if (this.productEmissionsErrorMessages.noteErrorMessages.length > 0) return;

      // Handle show popup confirm change data approved
      if (this.statusApproved && !this.isUpdateDataApproved) {
        this.isUpdateDataApproved = true;
        this.questionPopupDialog = true;
        this.questionPopupMessage = this.$t('cfp_emissions_product.message_confirm_updated_data_approved');
        this.confirmText = this.$t('register_data.button_keep');
        this.closeText = this.$t('popup.button_close');
        return;
      }
      // Prepare data call API
      const itemsRequired = ['type', 'name', 'emissions', 'activity_level', 'activity_level_unit'];
      if (this.processDetailInfo?.id) {
        const add = [];
        const edit = [];
        const dataDeleted = [];

        dataEditedOnTable.forEach((item) => {
          const isAllFieldsEmpty = itemsRequired.every(
            (field) => item[field] === null || item[field] === '' || item[field] === undefined,
          );

          if (Number(item.id)) {
            isAllFieldsEmpty ? dataDeleted.push(item.id) : edit.push(item);
          } else if (!isAllFieldsEmpty) {
            add.push({ ...item, id: undefined });
          }
        });
        this.emissionsProductGrid.itemsRemoved.forEach((item) => {
          if (item.id !== BLANK_ID) {
            dataDeleted.push(item.id);
          }
        });
        payload.process_detail.id = this.processDetailInfo.id;
        payload.data = { add, update: edit, delete: dataDeleted };
        this.saveEmissionProduct('put', payload);
      } else {
        // Case create new data
        const dataSubmit = dataEditedOnTable
          .filter(
            (item) =>
              !itemsRequired.every((field) => item[field] === null || item[field] === '' || item[field] === undefined),
          )
          .map(({ id, ...rest }) => rest);

        payload.data = dataSubmit;
        this.saveEmissionProduct('post', payload);
      }
    },
    async saveEmissionProduct(method, payload) {
      try {
        await actionProcessEmissionProduct(method, payload, this.processDetailInfo.process_id);
        this.dialog.notificationSaved = true;
        this.message.savedChange = this.$t('cfp_emissions_product.message_saved_changes');
      } catch (error) {
        this.dialog.notification = true;
        this.message.notification = Object.values(error?.errors).join('\n');
      }
    },

    async getDataChangeOnTable() {
      let dataRegisterOnTable = [];
      const listDbItemInSource = await this.getListDbItemInSourceCollection();
      const addItemToTable = (emissionsProcessItem) => {
        const index = this.emissionsProductGrid.items.indexOf(emissionsProcessItem) + 1;
        const databaseTypeObject = listDbItemInSource[index - 1];
        if (emissionsProcessItem?.evidence) {
          emissionsProcessItem.evidence.value = emissionsProcessItem?.amount_activity;
        }
        const dbRelationKey = getDbRelationKeysByType(databaseTypeObject?.type);
        const dbItemId = databaseTypeObject?.origin_id || databaseTypeObject?.id || null;
        dataRegisterOnTable.push({
          id: emissionsProcessItem.id,
          name: emissionsProcessItem?.item_name || '',
          activity_level: formatNumberBySetting(emissionsProcessItem?.amount_activity),
          activity_level_unit: emissionsProcessItem?.amount_activity_unit,
          emissions: this.formatValueNumber(emissionsProcessItem.emissions),
          boundary_id: this.processDetailInfo.boundary_id,
          product_id: this.processDetailInfo.product_id,
          process_id: this.processDetailInfo.process_id,
          product_emission_id: this.processDetailInfo.product_emission_id,
          cfp_workflow_supplier_id: emissionsProcessItem.cfp_workflow_supplier_id,
          value_source: emissionsProcessItem.value_source,
          unit_source: emissionsProcessItem.unit_source,
          source: emissionsProcessItem.source,
          type: databaseTypeObject?.type !== undefined ? databaseTypeObject.type : null,
          db_relation:
            databaseTypeObject?.type !== undefined
              ? {
                  [dbRelationKey]: dbItemId,
                }
              : null,
          db_customize_id: databaseTypeObject?.type === DB_TYPE.CUSTOMIZE ? dbItemId || null : null,
          db_master_id: databaseTypeObject?.type === DB_TYPE.MASTER ? dbItemId || null : null,
          index: index,
          evidence: emissionsProcessItem.evidence || null,
        });
      };

      this.emissionsProductGrid.itemsEdited.forEach(addItemToTable);
      this.emissionsProductGrid.itemsAdded.forEach(addItemToTable);
      return dataRegisterOnTable;
    },

    handleResize() {
      this.isMobile = window.innerWidth < 1024;
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    updateActionPosition() {
      const scrollElementTop = this.$refs.scrollElement?.offsetTop; // Vị trí của phần tử
      const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại
      if (scrollPosition > scrollElementTop + 215) {
        // scrollElementTop + 215 ===  the space between the buttons with top screen
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
    },

    hasDataChanged(original, current) {
      // current null return false
      if (Array.isArray(current)) {
        if (original.length !== current.length) {
          return true; // If lengths are different, data has changed
        }

        for (let i = 0; i < original.length; i++) {
          for (let key in original[i]) {
            if (original[i][key] !== current[i][key]) {
              return true; // Early exit on first detected change
            }
          }
        }
      }

      return false; // If nothing has changed, return false
    },
    checkTableHasData() {
      this.isHasDataTable = false;
      const hasDataInTable = this.hasDataChanged(this.listOriginalData, this.emissionsProductGrid?.sourceCollection);

      const hasDataInInputs =
        this.processDetailInfo.selectedOption !== this.processDetailInfo.methodOriginal ||
        this.formatValueNumber(this.processDetailInfo.productNumber) !==
          this.formatValueNumber(this.processDetailInfo.quantityOriginal) ||
        this.processDetailInfo.note !== this.processDetailInfo.noteOriginal;
      this.isHasDataTable = hasDataInTable || hasDataInInputs;
    },
    getDataPulldown() {
      this.checkTableHasData();
    },
    basicUnitSearchPopup(e, context) {
      if (this.isReadOnlyDataTable) return;
      this.selectedRow = context.row.index;
      this.basicUnitData.dialog = true;
    },
    closeBasicUnitPopup() {
      this.basicUnitData.dialog = false;
    },
    submitBasicUnitItem(basicUnitItem) {
      const dataAutoBinding = [];
      const values = basicUnitItem;
      const rowIndex = this.selectedRow;
      this.addNewItemToBasicUnitPulldown(basicUnitItem);

      const dataUpdateCell = {
        energy_type: basicUnitItem.id,
        wsu_unit: basicUnitItem.unit_source,
        wsu_value: basicUnitItem.value_source,
        wsu_source: basicUnitItem.name_basic,
        unit: basicUnitItem.unit,
      };
      if (rowIndex || rowIndex == 0) {
        this.basicUnitData.selectedValuePulldown[rowIndex + 1] = {
          id: basicUnitItem.id,
          source: basicUnitItem.name_basic,
        };
      }
      dataAutoBinding.push(dataUpdateCell);
      dataAutoBinding.forEach((item, index) => {
        const row = rowIndex + index;
        this.updateFlexGridCells(row, item);
        if (this.emissionsProductGrid.sourceCollection[row]) {
          this.emissionsProductGrid.sourceCollection[row].idDbExternal = basicUnitItem.id;
          const dbRelationKey = getDbRelationKeysByType(basicUnitItem.type_db);
          this.emissionsProductGrid.sourceCollection[row].db_relation = {
            [dbRelationKey]: values,
          };
          this.emissionsProductGrid.sourceCollection[row].detailExternalSourceId = values.id;
          this.emissionsProductGrid.sourceCollection[row].wsu_value = this.formatNumber25digit(
            basicUnitItem.value_source,
          );
        }
        this.updateEmissionsForRow(row);
      });
      this.updateTotalEmissionsHandler();
      this.checkTableHasData();
    },
    formatNumber25digit(num) {
      return formatNumberRealNum(num, { isAlowMore25digit: false });
    },
    handleAddValueSourceIntoGrid(dbItemAddNew, basicUnitName) {
      const itemInPullDown = this.basicUnitData.listPullDownByObjectName[basicUnitName];
      if (itemInPullDown) return;

      const newId = makeNewId(dbItemAddNew?.id, dbItemAddNew?.type);
      this.handleNewDataForPullDown({ basicUnitName, newId, itemInListAll: dbItemAddNew });
      this.basicUnitData.basicUnitUnique.push({ ...dbItemAddNew, id: newId });
      this.handleAfterSubmitBasicUnit();
    },
    async handleUpdateBasicUnitColumn() {
      // handle set item to pulldown
      const dbList = await findManyByIdsDataBasicUnit(this.basicUnitData.listUniqueIdForPulldown, true);
      const column = this.flexGrid.getColumn('energy_type');
      column.dataMap = new wjGrid.DataMap(dbList, 'id', 'name_basic');
      column.editor.itemsSource = dbList;
    },
    async getListAllUniqueItemName() {
      try {
        const responseData = await getListUniqueItemName();
        this.basicUnitData.listAllUniqueItemName = responseData.data;
      } catch (error) {
        console.warn(error);
      }
    },

    async getListUniqueIdForPullDown() {
      try {
        const responseData = await getListUniqueId({
          notIdea: 1,
          month: this.processDetailInfo.month,
          year: this.processDetailInfo.year,
        });
        this.basicUnitData.listUniqueIdForPulldown = [
          ...new Set([...responseData.data, ...this.basicUnitData.listUniqueIdForPulldown]),
        ];
        this.basicUnitData.listBasicUnitIdsByMonthYear = responseData.data;
      } catch (error) {
        console.warn(error);
      }
    },
    async getListAllUniqueId() {
      try {
        const responseData = await getListUniqueId();
        this.basicUnitData.listAllUniqueId = responseData.data;
      } catch (error) {
        console.warn(error);
      }
    },
    async getListUniqueItemNameForPullDown() {
      try {
        const responseData = await getListUniqueItemName({
          notIdea: 1,
          month: this.processDetailInfo.month,
          year: this.processDetailInfo.year,
        });
        this.basicUnitData.listUniqueNameBasicForPulldown = responseData.data;
      } catch (error) {
        console.warn(error);
      }
    },
    async handlePaste() {
      try {
        await navigator.clipboard.readText();
      } catch (err) {
        console.error('Failed to read clipboard contents: ', err);
        this.actionUpdateIsLoadingTable(false);
      }
    },
    addNewItemToBasicUnitPulldown(basicUnitItem) {
      if (!this.basicUnitData.listUniqueIdForPulldown.includes(basicUnitItem.id)) {
        this.basicUnitData.listUniqueIdForPulldown.push(basicUnitItem.id);
        this.basicUnitData.listUniqueNameBasicForPulldown.push(basicUnitItem.itemNameNew);
        addItemToPulldown({
          flexgrid: this.flexGrid,
          itemId: basicUnitItem.id,
          itemSource: basicUnitItem.name_basic,
          pulldownBinding: 'energy_type',
          dataMap: {
            key: 'id',
            value: 'name_basic',
          },
          wjGrid,
        });
      }
    },
    async getListDbItemInSourceCollection() {
      const listUniqueIdBasicUnitInSource = this.emissionsProductGrid.sourceCollection.map(
        (item) => item[['energy_type']] || Date.now(),
      );
      const responseData = await findManyByIdsDataBasicUnit(listUniqueIdBasicUnitInSource);
      return responseData;
    },
    async handleGetIdeaDetail(listIDEADetail) {
      let dbItem = await findManyByIdsDataBasicUnit(listIDEADetail);
      const listItems = [];
      dbItem.forEach((item) => {
        listItems.push({
          id: item.id,
          name_basic: item.name_basic,
        });
        this.basicUnitData.listUniqueIdForPulldown.push(item.id);
        this.basicUnitData.listUniqueNameBasicForPulldown.push(item.itemNameNew);
      });
    },
    async addValueToList(listDbItemNameAddNew) {
      try {
        this.basicUnitData.listUniqueNameBasicForPulldown.push(...listDbItemNameAddNew);
        const listDbItemAddNew = await findManyByIdsDataBasicUnitItemNameNew(listDbItemNameAddNew, true);
        listDbItemAddNew.forEach((item) => {
          this.basicUnitData.listUniqueIdForPulldown.push(item.id); //sync list id by list name listDbItemNameAddNew
        });
        await this.handleUpdateBasicUnitColumn();
      } catch (error) {
        console.warn(error);
      }
    },
    handleDefaultSelectedValuePulldown(dataProps) {
      const { index, item } = dataProps;
      this.basicUnitData.selectedValuePulldown[index] = {
        id: item.id,
        source: item.name_basic,
      };
    },
    initSupplierGridColumn() {
      const widthTooltip = 20;
      const layerIndex = {
        1: 'business_name',
        2: 'country',
        3: 'layer_3',
        4: 'layer_4',
        5: 'layer_5',
        6: 'layer_6',
      };
      const layers =
        this.listLayerBranch?.map((item) => {
          return {
            header: item.layer_name,
            visible: true,
            binding: layerIndex[item.layer_index],
            maxWidth: 980,
            minWidth: getWidthByTextContent(item.layer_name, widthTooltip),
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClassAll: 'submitted-color',
            multiLine: true,
          };
        }) || [];
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: this.$t('process_data_link.table_field_Status'),
          binding: 'status',
          minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_Status'), widthTooltip),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('process_data_link.table_field_link_form_name'),
          binding: 'linked_form_name',
          minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_link_form_name'), widthTooltip),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('process_data_link.table_field_approved'),
          binding: 'approver',
          minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_approved'), widthTooltip),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('process_data_link.table_field_date_sent'),
          binding: 'sent_date',
          minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_date_sent'), widthTooltip),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('process_data_link.table_field_date_approved'),
          binding: 'date_approved',
          minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_date_approved'), widthTooltip),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('b_list_all.table_organizational_information'),
          align: 'center',
          collapseTo: 'organizational_division',
          columns: [
            {
              header: this.$t('process_data_link.table_field_organizational_division'),
              binding: 'organizational_division',
              minWidth: getWidthByTextContent(
                this.$t('process_data_link.table_field_organizational_division'),
                widthTooltip,
              ),
              maxWidth: 350,
              allowSorting: false,
              isRequired: false,
              multiLine: true,
              wordWrap: true,
              cssClassAll: 'submitted-color',
            },
            {
              header: this.$t('process_data_link.table_field_company_name'),
              binding: 'company_name',
              minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_company_name'), widthTooltip),
              maxWidth: 980,
              allowSorting: false,
              isRequired: false,
              multiLine: true,
              wordWrap: true,
              cssClassAll: 'submitted-color',
            },
            ...layers,
          ],
        },
        {
          header: this.$t('process_data_link.table_field_link_form_memo_1'),
          binding: 'note',
          minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_link_form_memo_1'), widthTooltip),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('process_data_link.table_field_link_form_memo_2'),
          binding: 'note_2',
          minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_link_form_memo_2'), widthTooltip),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('process_data_link.table_field_product_name'),
          binding: 'product_name',
          minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_product_name'), widthTooltip),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('process_data_link.table_field_product_code'),
          binding: 'product_code',
          minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_product_code'), widthTooltip),
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('process_data_link.table_field_item_name'),
          binding: 'item_name',
          minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_item_name'), widthTooltip),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('process_data_link.table_source_unit'),
          align: 'center',
          cssClassAll: 'submitted-color',
          columns: [
            {
              header: this.$t('process_data_link.table_numeric_value'),
              binding: 'activity_level',
              minWidth: getWidthByTextContent(this.$t('process_data_link.table_numeric_value'), widthTooltip),
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              wordWrap: true,
              cssClassAll: 'submitted-color',
              align: 'right',
            },
            {
              header: this.$t('process_data_link.table_unit_value'),
              binding: 'activity_level_unit',
              minWidth: getWidthByTextContent(this.$t('process_data_link.table_unit_value'), widthTooltip),
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              wordWrap: true,
              cssClassAll: 'submitted-color',
            },
          ],
        },
        {
          header: this.$t('56_pattern.table_attach_file'),
          binding: 'attach_file',
          cssClass: 'auto-increment',
          minWidth: getWidthOfAttachedButton(this.$i18n.locale),
          maxWidth: getWidthOfAttachedButton(this.$i18n.locale),
          allowSorting: false,
          isRequired: false,
          cssClassAll: 'btn-db attach-file hide-filter',
          cellTemplate: (ctx, el) => {
            let text = this.$t('56_pattern.table_attach_file');
            if (ctx.item?.evidence) {
              text = `<div class="file-attached-container">
                <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
              </div>`;
            }
            const button = CellMaker.makeButton({
              text: text,
              click: (e, context) => this.attachFilePopup(e, context)
            });
            return button(ctx, el);
          },
        },
      ];
    },
    initSupplierGrid(grid) {
      this.supplierGrid = grid;
      grid.formatItem.addHandler((s, e) => {
        if (s.cells.cellType === wjGrid.CellType.Cell && e.panel == s.cells) {
          if (s.columns[e.col].binding === 'status') {
            e.cell.classList.add(this.colorStatusDataDetails(s.rows[e.row].dataItem));
          }
          if (s.columns[e.col].binding === 'attach_file') {
            const rowData = s.rows[e.row].dataItem;
            const evidence = rowData?.evidence;
            if (!evidence) {
              wjcCore.addClass(e.cell, 'btn-disabled');
            }
          }
        }
      });
      grid.pastingCell.addHandler((s, e) => {
        e.cancel = true;
      });
      grid.beginningEdit.addHandler((s, e) => {
        e.cancel = true;
      });
    },
    defineSupplierTable() {
      this.supplierCollection = new CollectionView([...this.supplierData]);
      if (this.supplierData.length) setMinMaxSizeColumns(this.supplierGrid, this.supplierData);
      if (this.supplierGrid) this.supplierGrid.columnGroups = this.initSupplierGridColumn();
    },
    isReturnOrReprensentReturnStatus(status) {
      return [STATUS_FIELD.STATUS_RETURN, STATUS_FIELD.STATUS_REPRENSENT_RETURN].includes(status);
    },
    statusDataDetails(rowStatus) {
      let status = getStatusNameById(rowStatus);

      if (rowStatus === STATUS_FIELD.STATUS_SUBMIT) {
        status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
      }

      if (rowStatus === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
        status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
      }
      return status;
    },
    colorStatusDataDetails(rowData) {
      if (rowData.workflowData.status === STATUS_FIELD.STATUS_SUBMIT) {
        if (!rowData?.is_active) {
          return `color-${getColorStatusById(STATUS_FIELD.STATUS_REPRENSENT_APPROVED)}`;
        }
        return `color-${getColorStatusById(STATUS_FIELD.STATUS_SUBMIT_NON_USER)}`;
      }

      if (rowData?.workflowData.status === STATUS_FIELD.STATUS_RETURN && !rowData?.is_active) {
        return `color-${getColorStatusById(STATUS_FIELD.STATUS_REPRENSENT_APPROVED)}`;
      }
      return `color-${getColorStatusById(rowData?.workflowData.status)}`;
    },
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.updateActionPosition);
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.emission-product {
  &__body {
    &__info {
      &__unit-section {
        display: flex;
        flex-direction: column;
        padding: 16px 20px;
        gap: 20px;
        border-radius: 4px 0px 0px 0px;
        .question {
          position: relative;
          height: 24px;
          margin-left: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .emission-quantity {
          label {
            display: flex;
            align-items: center;
          }
          ::v-deep .input-custom {
            &.input-service-link {
              &.color-textColor {
                &.input-field {
                  flex-grow: unset;
                }
              }
            }
          }
        }
        &__input-block {
          display: flex;
          flex-direction: column;
          gap: 6px;

          label {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.03em;
            text-align: left;
            color: $monoBlack;
            .tooltip {
              margin-left: 8px;
            }
          }

          .text-field {
            border: 1px solid $monoBlackDark;
            gap: 0px;
            border-radius: 4px 0px 0px 0px;
            border: 1px 0px 0px 0px;
            padding: 7px 16px 9px 16px;
            gap: 8px;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.03em;
            text-align: left;
            color: $monoBlack;
            word-wrap: break-word;
            overflow-wrap: break-word;
            &-unit {
              white-space: nowrap;
            }
          }
        }
      }

      &__note-section {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 16px 20px;
        ::v-deep .input-field {
          &.input-custom {
            height: unset;
            border: unset;
            background: transparent;
            box-shadow: unset;
            .v-input__slot {
              background: $monoWhite;
            }
          }
        }
        label {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.03em;
          text-align: left;
          color: $monoBlack;
        }
      }
    }
    &__table {
      ::v-deep .search-detail {
        &.hide-filter {
          .wj-btn-glyph {
            display: none;
          }
        }
      }
    }
    &.supplier-table {
      margin-top: 40px;
      ::v-deep {
        .color-blue {
          color: $blueDark2;
        }
        .color-red {
          color: $redMid;
        }

        .color-light-green {
          color: $monoMid !important;
        }
        .color-mono-black {
          color: $monoBlack;
        }
      }
    }
  }
}

@include desktop {
  .emission-product {
    &__body {
      &__info {
        &__unit-section {
          display: flex;
          flex-direction: row;
          padding: 16px 40px 16px 40px;
          gap: 20px;
          border-radius: 4px 0px 0px 0px;

          &__input-block {
            display: flex;
            flex-direction: column;
            gap: 6px;
            flex: 1;
            width: calc(100% / 3);
          }
        }

        &__note-section {
          padding: 16px 40px 24px 40px;
          gap: 8px;
          border-radius: 4px 0px 0px 0px;
        }
      }
    }
  }
}
</style>
